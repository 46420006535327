/**
 *
 * Custom function
 *
 */

(function ($) {

    /*
     *	Funzione per rendere un box tutto cliccabile. ATTENZIONE: all'interno del box deve esserci un unico link
     */

    function allClick() {
        var clickbox = $('.allclick');
        clickbox.each(function () {
            var $this = $(this);
            $this.css('cursor', 'pointer');
            $this.find('a').click(function (event) {
                event.preventDefault();
            });

            if ($this.find('a').attr('target') == '_blank') {
                $this.click(function () {
                    window.open($('a', this).attr('href'));
                });
            } else {
                $this.click(function () {
                    window.location.href = $('a', this).attr('href');
                });
            }
        });
    }

    /*
     *	Caricamento ajax delle immagini dal marcatore [data-loadimg] e dei background dal marcatore [data-loadbackground]
     */

    function loadAsynchronousImage() {

        $('*[data-loadimg]').each(function () {
            var box = $(this),
                src = box.attr('data-loadimg'),
                alt = '',
                img = new Image();

            if (box.attr('data-alt')) {
                alt = box.attr('data-alt');
            }

            $(img).load(function () {
                box.append('<img src="' + src + '" alt="' + alt + '" width="' + img.width + '" height="' + img.height + '">');
                $('img', box).hide().fadeIn();

            }).attr('src', src);
        });

        $('*[data-loadbackground]').each(function () {
            var box = $(this),
                src = box.attr('data-loadbackground');

            box.css({
                'background-image': 'url(' + src + ')',
                'background-repeat': 'no-repeat',
                'background-position': 'top center'
            });

            if (box.has(['data-position'])) {
                box.css({
                    'background-position': box.attr('data-position'),
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            } else {
                box.css({
                    'background-position': 'top center',
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            }

            if (box.attr('data-repeat') == 'repeat') {
                box.css({
                    'background-repeat': 'repeat'
                });
            } else {
                box.css({
                    'background-repeat': 'no-repeat'
                });
            }
        });
    }


    /*
     *	Funzione per la gestione dei bottoni "condividi" legati ai social
     */

    function socialOpen() {
        $('[data-socialurl]').each(function () {
            var $this = $(this),
                url = $this.attr('data-socialurl');

            $this.click(function () {
                window.open(url, '', 'width=500, height=500');
            });

        });
    }

    function trackAdwordsCampaign() {
        //$('body').append('<img height="1" width="1" style="border-style:none;" alt="" src=""/>');
    }


    /*
     *	Funzione per l'apertura di una finestra modale tramite cookie
     */

    function openModal() {
        if ($('#onloadmodal').length) {
            if (!$.cookie("openmodal")) {
                $('#onloadmodal').modal();
                $.cookie("openmodal", 'no', {
                    path: '/'
                });
            }
        }
    }



    /*
     *	Funzione per l'apertura di un iframe all'interno di una finestra modale
     */

    function iframeModalOpen() {
        $('.videoModal').each(function () {
            // impostiamo gli attributi da aggiungere all'iframe es: data-src andrà ad impostare l'url dell'iframe
            $('.modalButton').on('click', function (e) {
                var src = $(this).attr('data-src');
                var width = $(this).attr('data-width') || 640; // larghezza dell'iframe se non impostato usa 640
                var height = $(this).attr('data-height') || 360; // altezza dell'iframe se non impostato usa 360

                var allowfullscreen = $(this).attr('data-video-fullscreen'); // impostiamo sul bottone l'attributo allowfullscreen se è un video per permettere di passare alla modalità tutto schermo

                // stampiamo i nostri dati nell'iframe
                $(".videoModal iframe").attr({
                    'src': src,
                    'height': height,
                    'width': width,
                    'allowfullscreen': ''
                });
            });

            // se si chiude la modale resettiamo i dati dell'iframe per impedire ad un video di continuare a riprodursi anche quando la modale è chiusa
            $(this).on('hidden.bs.modal', function () {
                $(this).find('iframe').html("");
                $(this).find('iframe').attr("src", "");
            });
        });
    }

    /*
     *	Funzione per la formattazione delle tabelle con classe listino
     *	tramite le classi di bootstrap
     */

    function responsiveTable() {

        $('.entry-content table').each(function () {
            var $this = $(this);
            $this.addClass('table');
            $this.wrap('<div class="table-responsive"></div>');
        });

        if ($(window).width() <= 767) {

            $('#primary .table-responsive').prepend(
                '<span class="angle-scroll-left">' +
                '<i class="icon icon-edt-arrow-right-6 angle-scroll-left__animation"></i>' +
                '</span>'
            );

            $('#primary .table-responsive').on('scroll', function (event) {
                var angleScrollLeft = $('.angle-scroll-left');

                if ($(this).scrollLeft() >= 1) {
                    angleScrollLeft.addClass('fade-out');
                } else {
                    angleScrollLeft.removeClass('fade-out');
                }
            });

            $('#primary').find('.table-responsive').each(function () {
                var table = $(this);
                $('.angle-scroll-left').click(function (event) {
                    table.animate({
                        scrollLeft: '+=60'
                    }, 200);
                });
            });
        }
    }

    /*
     *	Funzione per l'inizializzazione della mappa
     */
    function initializeGmap() {
        $('*[data-location]').each(function () {
            var map = $(this),
                address = map.attr('data-location');
            map.setGMap({
                address: address
            });
        });
    }


    /**
     * Funzione per l'attivazione del menu responsive
     */

    function responsiveMenu(viewportSize) {
        $('.main-navigation').find('a[href="#"]').each(function () {
            $(this).click(function (event) {
                event.preventDefault();
            });
        });

        // if ($(window).width() <= viewportSize) { // se minore o uguale a 1199px di default
        var menuPrimaryOffcanvas = $("#js-menu-primary-offcanvas");
        var fixedMenu = $('#js-fixed-menu')[0];
        var layerMenu = $('.layer-menu');

        // var tl = new TimelineMax({ onComplete: function() {
        //     menuPrimaryOffcanvas.addClass("offcanvas-open");
        // }});

        if (!fixedMenu) return;

        var stickyHeader = new Waypoint.Sticky({
            element: fixedMenu
        });

        $('.main-navigation').find('a[href="#"]').each(function () {
            $(this).click(function (event) {
                $('ul.sub-menu').removeClass('open');
                $(this).next().toggleClass('open');
            });
        });

        $("#js-menu-offcanvas-button-open").click(function () {
            
            var tl = new TimelineMax();
			
            tl.to(layerMenu, 0.3, {
                autoAlpha: 0.5,
                ease: Power2.easeOut,
                onComplete: function () {
                    menuPrimaryOffcanvas.addClass("offcanvas-open");
                    $('#page').addClass('blur');
                    $('html').addClass('no-scroll');
                }
            });

            tl.staggerFromTo(menuPrimaryOffcanvas.find('.menu-item a, .gsap-stagger-menu'), 0.5, {
                y: -40,
                opacity: 0
            }, {
                y: 0,
                opacity: 1,
                ease: Back.easeOut.config(1.2),
                delay: 0.2
            }, 0.1);

            return tl;
        });

        $("#js-menu-offcanvas-button-close, .layer-menu").click(function () {

            TweenMax.to(layerMenu, 0.3, {
                autoAlpha: 0,
                ease: Power2.easeOut
            });
            menuPrimaryOffcanvas.removeClass("offcanvas-open");
            $('#page').removeClass('blur');
            $('html').removeClass('no-scroll');
        });

        // } else {
        //     var stickyHeader = new Waypoint.Sticky({
        //         element: $('#js-header')[0],
        //         // offset: -250 // l'offset è utile se non vuoi far partire subito fisso il menu
        //     });
        // }
    }


    /*
     *  Funzioni per PhotoSwiper
     */
    function addImageSizeForPhotoSwiper() {
        var thumbs = $('img[data-big-size]');
        thumbs.each(function (index) {
            if ($(this).parent('a').length > 0) {
                var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
                if (isImageLink) {
                    big_size = $(this).data('big-size');
                    link = $(this).parent('a');
                    link.attr('data-size', big_size);
                    link.wrap('<div class="single-image"></div>');
                }
            }
        });
    }

    function initPhotoSwiper(selector) {
        var galleryItems = $(selector);
        var pswpItems = [];
        galleryItems.each(function (index) {
            var dimensions = $(this).find('a').data('size').split('x');
            var width = parseInt(dimensions[0], 10);
            var height = parseInt(dimensions[1], 10);

            myImage = {
                src: $(this).find('a').attr('href'),
                w: width,
                h: height,
                msrc: $(this).find('img').attr('src')
            };

            pswpItems.push(myImage);
        });


        galleryItems.each(function (index) {
            $(this).click(function (evt) {
                evt.preventDefault();
                openPhotoSwipe(pswpItems, index);
            });
        });
    }

    function openPhotoSwipe(galleryItems, index) {
        var options = {
            index: index
        };
        var pswpElement = $('.pswp')[0];

        var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
        gallery.init();
    }


    function smoothScroll() {
        $("a[href*='#']").not("[href='#'],[data-toggle]").click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {

                    if (target.attr('data-paddingTopScroll')) {
                        var adjust = target.attr('data-paddingTopScroll');
                        console.log(adjust)
                    } else {
                        var adjust = target.css('padding-top') ? target.css('padding-top').replace('px', '') : 0;
                    }

                    $('html, body').animate({
                        scrollTop: target.offset().top - adjust
                    }, 1000);

                    return false;
                }
            }
        });
    }

    function startOwl(selector) {
        $(selector).each(function (arguments) {
            var owl = $(this);
            var params = {
                nav: owl.data('owl-navigation'),
                navSpeed: owl.data('owl-slide-speed'),
                autoplay: owl.data('owl-autoplay'),
                dots: owl.data('owl-dots'),
                dotsSpeed: owl.data('owl-dots-speed'),
                loop: owl.data('owl-loop'),
                autoHeight: false,
                lazyLoad: true,
                navText: [
                    "<i class='icon-edt-arrow-left-2'></i>",
                    "<i class='icon-edt-arrow-right-2'></i>"
                ]
            };

            if (owl.data('owl-single-item')) {
                params['items'] = 1;
            } else {
                params['responsive'] = {
                    0: {
                        items: owl.data('owl-items-xs'),
                    },
                    768: {
                        items: owl.data('owl-items-sm'),
                    },
                    992: {
                        items: owl.data('owl-items-md'),
                    },
                    1200: {
                        items: owl.data('owl-items-lg'),
                    },
                    1440: {
                        items: owl.data('owl-items-xl'),
                    }
                };
            }

            owl.owlCarousel(params);

            if (owl.data('owl-prev')) {
                $(owl.data('owl-prev')).click(function () {
                    owl.trigger('prev.owl.carousel');
                });
            }
            if (owl.data('owl-next')) {
                $(owl.data('owl-next')).click(function () {
                    owl.trigger('next.owl.carousel');
                });
            }
        });
    }

    /*****************************************************************************************/

    function toConsole(string) {
        if ((typeof window.console == "undefined")) {
            alert(string);
        } else console.log(string);
    }

    function $j(argument) {
        var result = jQuery(argument);
        if (result.length === 0) return null;
        else return result;
    }

    function translateCountdown(langCountdown) {

        countdown.resetLabels();

        if (langCountdown == 'it') {
            countdown.setLabels(
                ' millisecondo| secondo| minuto| ora| giorno| settimana| mese| anno| decade| secolo| millennio',
                ' millisecondi| secondi| minuti| ore| giorni| settimane| mesi| anni| decadi| secoli| millenni',
                ' e ',
                ' '
            );
        } else if (langCountdown == 'de') {
            countdown.setLabels(
                ' Millisekunde| zweite| Minute| jetzt| Tag| Woche| Monat| Jahr| Jahrzehnt| Jahrhundert| Jahrtausend',
                ' Millisekunden| Sekunden| Minuten| Stunden| Tage| Wochen| Monate| Alter| Jahrzehnte| Jahrhunderte| Jahrtausende',
                ' und ',
                ' '
            );
        } else if (langCountdown == 'fr') {
            countdown.setLabels(
                ' milliseconde| deuxième| minute| maintenant| jour| semaine| mois| année| décennie| siècle| millénaire',
                ' millisecondes| secondes| minutes| heures| journées| semaines| mois| âge| décennies| siècles| millénaires',
                ' et ',
                ' '
            );
        }

    }

    function edtCountdown() {

        jQuery(".edt_countdown_class").find('.item').each(function () {

            var dataInizio = "";
            var questo = jQuery(this);
            jQuery.post(
                url.ajax_url, {
                    'action': 'edita_ajax_get_start_countdown_date',
                    'offerId': questo.data('offerid'),
                },

                function (response) {
                    setInterval(function () {
                        stampaCountdown(questo, response);
                    }, 3600);
                }
            );
        });
    }

    function stampaCountdown(item, response) {

        jQuery(item).find('.pageTimer').empty().append(
            moment().countdown(response, countdown.DAYS | countdown.HOURS).toString()
        );
    }

    function closePhotoswipe() {

        $('.pswp__button--close').click(function () {
            if ($('.pswp').hasClass('pswp--open')) {
                $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
            }
        });

        $(window).scroll(function () {
            if ($('.pswp').hasClass('pswp--open')) {
                $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
            }
        });
    }

    /*
     * Funzione per utilizzare MixItUp da select
     */

    function filterElements() {
		
		var container = '.mix-filter-support';

		if ( $(container).length ) {
			var mixer = mixitup(container, {
				selectors : {
					control : '[data-mixitup-control]'
				}
			});
		}
	}

    /**
     * Questo oggetto ci serve a verificare il dispositivo utilizzato dall'utente,
     * la i. nell'espressione regolare sta ad indicare una ricerca case-insensitive,
     * il metodo nativo test() serve a verificare la presenza della parola nell'espressione regolare,
     * ritorna un boleano.
     */
    var mapsMobileUrl = {
        isAndroid: function () {
            return /(android)/i.test(navigator.userAgent);
        },
        isIos: function () {
            return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
        }
    };

    
    function injectorLinkMaps() {
		var buttonMaps = $('.dove-siamo-button').find('.navigator');

		if ( mapsMobileUrl.isAndroid() ) buttonMaps.attr( 'href', phpMapsMobileUrl.android );
		if ( mapsMobileUrl.isIos() ) buttonMaps.attr( 'href', phpMapsMobileUrl.ios );
		if ( ! mapsMobileUrl.isAndroid() && ! mapsMobileUrl.isIos() ) buttonMaps.hide();
	}
    

    // funzione per l'aggiunta dell'effetto parallasse sull'hover
    function parallax(e, target, layer, label) {
        var layer_coeff = 40 / layer;
        var x = ($(window).width() - target[0].offsetWidth) / 8 - (e.originalEvent.layerX - ($(window).width() / 8)) / layer_coeff;
        var y = ($(window).height() - target[0].offsetHeight) / 8 - (e.originalEvent.layerY - ($(window).height() / 8)) / layer_coeff;

        if (label == 'scritte') y = 0;

        TweenMax.to(target, 2, {
            x: x,
            y: y,
            ease: Power1.easeOut,
            rotation: 0.001 // su alcuni browser l'immagine viene animata in modo leggermente scattoso impostare una rotation ad un valore bassissimo risolve il problema https://greensock.com/forums/topic/11038-jumpy-choppy-css-transform-animations/
        });

        return {
            x: x,
            y: y
        }
    }
	
	
	/*
    function highlightAnimation() {
        var highlight = $('.highlight');
        var highlightTitle = $('.highlight').find('.highlight-title');

        // closure per gestione animazione elementi interni alla sezione con timeline
        function gsapAnimInview(target) {
            var tl = new TimelineMax();

            tl.fromTo($(target), 1.2, {
                y: 200
            }, {
                opacity: 1,
                y: 0,
                ease: Power4.easeOut
            });

            tl.fromTo($(target).find('.highlight-data'), 0.6, {
                x: -10000
            }, {
                x: 0,
                ease: Power4.easeOut
            }, '-=1');

            tl.staggerFromTo($(target).find('.highlight-data .gsap-stagger'), 0.8, {
                y: 500
            }, {
                y: 0,
                ease: Power4.easeOut
            }, 0.2, '-=0.5');

            return tl;
        }

        // la prima parola del titolo di sezione deve avere uno stile particolare
        highlightTitle.each(function () {
            var text = $(this),
                t = text.text().split(' ');

            text.html('<span class="big">' + t.shift() + '</span>' + t.join(' '));
        });

        // aggancio un'istanza di waypoint per ogni sezione di modo che quando entra in viewport scatti l'animazione
        highlight.each(function (key, value) {
            new Waypoint({
                element: $(value),
                initAnimation: false,
                handler: function (direction) {

                    if (!this.initAnimation) gsapAnimInview(value);

                    this.initAnimation = true;
                },
                offset: '70%'
            });
        });

        // applico l'effetto di parallasse al movimento del mouse
        highlight.mousemove(function (event) {
            parallax(event, $(this).find('.highlight-image'), 1, 'immagine'); // effetto parallasse sull'immagine
            parallax(event, $(this).find('.gsap-parallax'), 2, 'scritte'); // effetto parallasse sulle scritte
        });

        // resetto la posizione degli elementi precedentemente spostati dall'effetto di parallasse
        highlight.mouseleave(function (event) {
            TweenMax.to($(this).find('.highlight-image, .gsap-parallax'), 0.5, {
                x: 0,
                y: 0,
                ease: Power2.easeOut
            });
        });
    }
	*/
	
    function headerAnimation() {
        // animazione payoff
        TweenMax.staggerFromTo($('.gsap-payoff-stagger'), 0.8, {
            opacity: 0,
            y: -40
        }, {
            opacity: 1,
            y: 0,
            ease: Back.easeOut.config(1.2)
        }, 0.1);

        // effetto pacchetto regalo
        if (!$('body').hasClass('.mobile')) {
            $(window).scroll(function () {
                if ($(window).scrollTop() >= 20 && $(window).scrollTop() <= 60) {
                    var el = $('#js-buttonHead-gift i'),
                        newone = el.clone(true);
                    el.before(newone);
                    el.last().remove();
                }
            });
        }
    }

    /**
     * Funzione per nascondere e visualizzare la testata allo scroll della pagina
     */

    function scrollHeader() {
        $('#js-fixed-menu').scrollHide({
            scrollDelta: 10,
            scrollOffset: 110,
            scrollClass: 'scrh-header',
            scrollClassHide: 'scrh-hide',
            onScroll: function (context, msg, currentTop) {
                if (msg === 1 && currentTop !== 0) {
                    $(context).addClass('scrh-up');
                    $('.bollo-whatsapp').addClass('bollohide');
                } else {
                    $(context).removeClass('scrh-up');
                    $('.bollo-whatsapp').removeClass('bollohide');
                }
            }
        });
    }

    function playVideoCamere() {
        // play del video di youtube al click sull'immagine della pagina camere
        var playVideo = true;

        $('.container-camera-video').click(function () {
            if (playVideo) {
                var iframe = $(this).find('iframe');
                var src = iframe.attr('src');
                iframe.attr('src', src + '&autoplay=1');

                TweenMax.to($(this).find('.camera-video-preview'), 1.1, {
                    autoAlpha: 0,
                    delay: 0.4
                });
            }

            playVideo = false;
        });
    }

    /**
     * detect IE
     * returns version of IE or false, if browser is not Internet Explorer
     * https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie-with-jquery#answer-21712356
     */
    function detectIE() {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }

    /**
     * Funzione per l'attivazione del form
     */
    function toggleForm(viewportSize) {
        var formWrapper = $(".efp-form-wrapper");
        var formContatti = $(".edita-form-builder");
        var formFake = $('#form-placeholder');
        var buttonClose = $('#button-close');
        // var infoBooking = $('.container-info-booking');
        // var infoBookingH = infoBooking.height();
        var formContattiH = formContatti.find('.row').height();
        var layerMenu = $('.layer-menu');
        var tl = new TimelineMax();

        // infoBooking.height(0);

        var formInnerClick = function (event) {

            tl.to(layerMenu, 0.3, {
                autoAlpha: 0.5,
                ease: Power2.easeOut
            });

            layerMenu.css({
                'z-index': 50
            });

            formFake.addClass("fake-open");

            formContatti.css({
                "min-height": '100%',
                'overflow': 'visible'
            }).animate({
                height: formContattiH
            }, 500);

            // if (detectIE()) formContatti.css('display', 'block');

            buttonClose.css('opacity', '1');

            $('.efp-popup-container').css({
                'opacity': '0',
                'visibility': 'hidden'
            });

            // setTimeout( function(){
            //     infoBooking.css( { display: 'block' } ).animate( { height: infoBookingH }, 500 );
            // }, 100 );

            formFake.addClass("fake-close");

            $(this).off();
        };

        // $('.efp-form-inner').click( formInnerClick );

        $(".closePopupForm").click(function () {

            layerMenu.css({
                'z-index': 300
            });

            formContatti.css({
                "min-height": '0px',
                "overflow": 'hidden'
            });

            // if (detectIE()) formContatti.css('display', 'none');

            formContatti.animate({
                height: '0px'
            }, 500, 'linear', function () {
                formWrapper.removeClass("fake-open");
                formFake.removeClass("fake-close");
            });

            // infoBooking.animate({
            //     height: '0px'
            // }, 500, 'linear', function() {
            //     $(this).css( { display: 'none' } )
            // });

            buttonClose.css('opacity', '0');

            TweenMax.to(layerMenu, 0.3, {
                autoAlpha: 0,
                ease: Power2.easeOut
            });

            setTimeout(function () {
                $('.efp-popup-container').css({
                    'opacity': '1',
                    'visibility': 'visible'
                });

                $('.efp-form-inner').click(formInnerClick);
            }, 300);
        });

    }


    function slideMobile() {
        if (!$('body').hasClass('is-mobile')) return;
		
		if( !$('.header-slideshow').length ) return;
		
        if ($(window).width() > 767) {
            $('.header-slideshow img.header-slide').attr('src', mobileSize.tablet[0])
        } else {
            $('.header-slideshow img.header-slide').attr('src', mobileSize.mobile[0])
        }
    }


    $j(document).ready(function () {

        allClick();
        responsiveTable();
        addImageSizeForPhotoSwiper();
        iframeModalOpen();
        responsiveMenu();
        filterElements();
        injectorLinkMaps();
        //if (!$('body').hasClass('is-mobile')) highlightAnimation();
        scrollHeader();
        playVideoCamere();
        toggleForm(1199);
        slideMobile();

        var etiquetteOpenLang = 'leggi tutto';
        if ($('body').hasClass('lang-en')) etiquetteOpenLang = 'read more';
        if ($('body').hasClass('lang-de')) etiquetteOpenLang = 'Weiterlesen';
        if ($('body').hasClass('lang-fr')) etiquetteOpenLang = 'tout lire';

        $('.feedback-description').collapser({
            mode: 'words',
            truncate: 30,
            showText: etiquetteOpenLang,
            hideText: etiquetteOpenLang,
            // controlBtn: 'opener'
        });

        // $('.home .entry-content').collapser({
        // 	mode: 'words',
        // 	truncate: 60,
        // 	showText: '+',
        // 	hideText: false,
        //     // controlBtn: 'opener'
        // });

        startOwl('.owl-carousel');
        smoothScroll();

        closePhotoswipe();

        var mixFilter = $('.mix-filter-support');
        if (mixFilter.length) mixitup(mixFilter);


        if (typeof lang && lang != 'en') {
            translateCountdown(lang);
        }

        edtCountdown();

        $('.gform_wrapper form').each(function () {

            $(this).checkEditaForm();
        });


        $('.checkNewsletter').each(function () {
            $(this).checkEditaForm();
        });

        $('#booking').checkEditaForm();

        var buttonBookingFixed = $('.button-booking-fixed');

        $(window).scroll(function (event) {
            var scroll = $(window).scrollTop();

            if (scroll > 900 && !$('body').hasClass('single-camere')) {
                buttonBookingFixed.addClass('open');
            } else {
                if (!$('body').hasClass('single-camere')) buttonBookingFixed.removeClass('open');
            }
        });
        
        // offerte filter menu
		
		$('#filter-list li a').click(function(e) {
			
			e.preventDefault();
			
			$('#filterMenu').html($(this).data('title'));
		});
    });


    $j(window).load(function () {
        /*
         *	Attraverso il Google Loader carico il modulo mappa
         */
        if ($('body').hasClass('page-template-template-mappa')) {

            $.getScript('https://www.google.com/jsapi', function () {
                if (url.key) {
                    var map_params = 'language=' + url.lingua + '&key=' + url.key;
                } else {
                    var map_params = 'language=' + url.lingua;
                }

                google.load('maps', '3', {
                    other_params: map_params + '&libraries=places',
                    callback: function () {
                        initializeGmap();
                    }
                });
            });
        }

        var gallery = $('.gallery');

        if (gallery.length) {
            gallery.each(function (index) {

                initPhotoSwiper("#" + $(this).attr('id') + " .gallery-item");
            });
        }

        initPhotoSwiper(".entry-content .single-image");

		$('.antiprima-evento').equalHeights();
		$('.antiprima-offerta').equalHeights();

        $('.section-form .form-group').equalHeights();
        if (!$('body').hasClass('is-mobile')) {
            $('.feedback-container, .booking-container').equalHeights();
            var feedbackContainerH = $('.feedback-container').outerHeight();
            $('.booking-container').css("min-height", feedbackContainerH);
        }

        if ($(window).width() > 767) $('.js-offertH .offert-articleData').equalHeights();

        headerAnimation();
    });

    $j(window).resize(function () {



    });

})(jQuery);
